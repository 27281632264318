const appUrl = `${window.location.origin}/`
const appDomain = window.location.hostname
export default {
  name    : 'Env',
  computed: {
    isStaging () {
      return process.env.VUE_APP_ENV === 'staging'
    },
    isDevelopment () {
      return process.env.VUE_APP_ENV === 'development'
    },
    isProduction () {
      return process.env.VUE_APP_ENV === 'production'
    },

    VUE_APP_DOMAIN () {
      if (this.isProduction) return appUrl

      return process.env.VUE_APP_DOMAIN
    },
    VUE_APP_API_BASE_URL () {
      if (this.isProduction) return appUrl

      return process.env.VUE_APP_API_BASE_URL
    },
    VUE_APP_GRAPHQL_HTTP () {
      if (this.isProduction) return `${appUrl}graphql`

      return process.env.VUE_APP_GRAPHQL_HTTP
    },
    VUE_APP_WEBSOCKET_URL () {
      if (this.isProduction) return `wss://${appDomain}/`

      return process.env.VUE_APP_WEBSOCKET_URL
    }
  }
}
