import { render, staticRenderFns } from "./SectionHeader.vue?vue&type=template&id=e0ea6e3e&scoped=true"
import script from "./SectionHeader.vue?vue&type=script&lang=js"
export * from "./SectionHeader.vue?vue&type=script&lang=js"
import style0 from "./SectionHeader.vue?vue&type=style&index=0&id=e0ea6e3e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0ea6e3e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VBtn,VCol,VIcon})
