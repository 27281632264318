/* eslint-disable no-sequences */

/**
 * Smooth-scrolls to the top of the page.
 *
 * Get distance from top using document.documentElement.scrollTop or document.body.scrollTop.
 * Scroll by a fraction of the distance from the top. Use window.requestAnimationFrame() to animate the scrolling.
 *
 * @example scrollToTop();
 */
export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop)
    window.scrollTo(0, c - c / 8)
  }
}

/**
 * Generates a UUID in a browser.
 *
 * Use crypto ApiClient to generate a UUID, compliant with RFC4122 version 4.
 *
 * @example UUIDGeneratorBrowser(); // '7982fcfe-5721-4632-bede-6000885be57d'
 *
 * @return {*}
 * @constructor
 */
export const UUIDGeneratorBrowser = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
