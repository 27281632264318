import { render, staticRenderFns } from "./OptimalFixedRatioBlock.vue?vue&type=template&id=5b95fbc8&scoped=true"
import script from "./OptimalFixedRatioBlock.vue?vue&type=script&lang=js"
export * from "./OptimalFixedRatioBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b95fbc8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VLazy,VRow})
