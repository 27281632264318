import { render, staticRenderFns } from "./ForecastFlowRuglSummaryCard.vue?vue&type=template&id=b6e146b0"
import script from "./ForecastFlowRuglSummaryCard.vue?vue&type=script&lang=js"
export * from "./ForecastFlowRuglSummaryCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VCardText,VLazy})
