export const arrayUtils = require('./array/index')
export const browserUtils = require('./browser/index')
export const colorUtils = require('./color/index')
export const helperUtils = require('./helper/index')
export const objectUtils = require('./object/index')
export const stringUtils = require('./string/index')
export const typeUtils = require('./type/index')
export const themeUtils = require('./theme/index')

const utils = {
  array  : arrayUtils,
  browser: browserUtils,
  color  : colorUtils,
  helper : helperUtils,
  object : objectUtils,
  string : stringUtils,
  type   : typeUtils,
  theme  : themeUtils
}

export default utils
