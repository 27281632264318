import { render, staticRenderFns } from "./HedgingCostsEstimatedBankFeesTableBlock.vue?vue&type=template&id=c2353722&scoped=true"
import script from "./HedgingCostsEstimatedBankFeesTableBlock.vue?vue&type=script&lang=js"
export * from "./HedgingCostsEstimatedBankFeesTableBlock.vue?vue&type=script&lang=js"
import style0 from "./HedgingCostsEstimatedBankFeesTableBlock.vue?vue&type=style&index=0&id=c2353722&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2353722",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
