import BaseResource                                   from '@/lib/data/resource/BaseResource'
import i18n                                           from '@/lang/lang'
import { toDecimalMark, toNumberFormatNotationShort } from '@/lib/utils/helper'
import AssetModel                                     from '@/api/models/asset/AssetModel'

export default class RiskExposuresAssetAggregateResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Asset = payload.itemName ? (payload.itemName === 'portfolio' ? i18n.t('Common.Asset.Portfolio') : payload.itemName) : ''

    this.DetailAsset = new AssetModel(payload.detailedAsset ?? {})

    this.Exposure = payload.exposure ? toNumberFormatNotationShort(payload.exposure) : ''
    this.ExposureRaw = payload.exposure ?? ''

    this.LocalExposure = payload.localExposure ? toNumberFormatNotationShort(payload.localExposure) : ''
    this.LocalExposureRaw = payload.localExposure ?? ''

    this.ExposurePercent = payload.exposurePercent ? toDecimalMark(payload.exposurePercent) : ''
    this.ExposurePercentRaw = payload.exposurePercent ?? ''

    this.DisplayLocalExposure = payload.displayLocalExposure ?? false
    this.DisplayUnit = payload.displayUnit ?? ''
    this.DisplayUnitPosition = payload.displayUnitPosition ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
