<template>
  <div
    v-ripple="expanded"
    :style="`background-color: ${backgroundColor};${expanded ? 'cursor: pointer' : ''}`"
    class="pa-3 mb-2 rounded d-flex"
    @click="onItemClick"
  >
    <v-icon
      v-if="iconColor"
      :color="iconColor"
      size="12"
      class="pt-1"
    >
      {{ icon }}
    </v-icon>

    <template v-if="!cols">
      <div
        :class="`${iconColor ? 'pl-2' : 'pl-1'} d-inline-block text-no-wrap synthesis-brand-dark-blue--text ${titleClass} text-size-${titleSize} ${titleSize === 14 ? 'pt-1' : ''}`"
      >
        {{ title }}
        <slot name="tooltip">
          <infotip
            v-if="tooltip"
            icon-style="margin-top: -12px"
          >
            {{ tooltip }}
          </infotip>
        </slot>
      </div>
      <v-spacer v-if="!!titleValue.length" />
      <div
        v-if="!!titleValue.length"
        :class="`pl-1 pr-2 d-inline-block text-size-${titleValueSize} ${titleValueColor}--text ${titleValueClass}`"
      >
        <span
          v-if="baseCurrencySymbol && !isBaseCurrencySymbolSingle"
          :class="`pt-5 pr-1 text-size-10 ${titleValueColor}--text`"
          v-text="baseCurrencySymbol"
        />{{ baseCurrencySymbolSingle + titleValue }}
      </div>
    </template>

    <template v-else>
      <v-container
        fluid
        class="pa-0 ma-0"
      >
        <v-row
          no-gutters
          style="line-height: initial;"
        >
          <v-col>
            <div
              :class="`${iconColor ? 'pl-2' : 'pl-1'} d-inline-block text-no-wrap synthesis-brand-dark-blue--text ${titleClass} text-size-${titleSize} ${titleSize === 14 ? 'pt-1' : ''}`"
            >
              {{ title }}
              <slot name="tooltip">
                <infotip
                  v-if="tooltip"
                  icon-style="margin-top: -12px"
                >
                  {{ tooltip }}
                </infotip>
              </slot>
            </div>
          </v-col>
          <v-col class="text-center">
            <template v-if="!!middleValue.length">
              <div
                style="min-width: 86px"
                :class="`d-inline-block text-end text-no-wrap synthesis-brand-dark-blue--text ${titleMiddleValueClass} text-size-${titleSize} ${titleSize === 14 ? 'pt-1' : ''}`"
              >
                {{ middleValue }}
              </div>
            </template>
          </v-col>
          <v-col class="text-end">
            <div
              v-if="!!titleValue.length"
              :class="`pl-1 pr-2 d-inline-block text-size-${titleValueSize} ${titleValueColor}--text ${titleValueClass}`"
            >
              <span
                v-if="baseCurrencySymbol && !isBaseCurrencySymbolSingle"
                :class="`pt-5 pr-1 text-size-10 ${titleValueColor}--text`"
                v-text="baseCurrencySymbol"
              />{{ baseCurrencySymbolSingle + titleValue }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>

import Infotip from '@/theme/default/components/common/Infotip'

export default {
  name      : 'ItemChartLegend',
  components: { Infotip },
  props     : {
    backgroundColor: {
      type   : String,
      default: ''
    },
    icon: {
      type   : String,
      default: 'mdi-checkbox-blank-circle'
    },
    iconColor: {
      type   : String,
      default: ''
    },
    baseCurrencySymbol: {
      type   : String,
      default: ''
    },
    title: {
      type   : String,
      default: ''
    },
    titleSize: {
      type   : [String, Number],
      default: 16
    },
    titleValue: {
      type   : String,
      default: ''
    },
    titleClass: {
      type   : String,
      default: 'font-weight-regular'
    },
    titleValueColor: {
      type   : String,
      default: 'synthesis-brand-dark-blue'
    },
    titleValueSize: {
      type   : [String, Number],
      default: 16
    },
    titleValueClass: {
      type   : String,
      default: 'font-weight-regular'
    },
    tooltip: {
      type   : String,
      default: ''
    },
    expanded: {
      type   : Boolean,
      default: false
    },
    cols: {
      type   : Boolean,
      default: false
    },
    middleValue: {
      type   : String,
      default: ''
    },
    titleMiddleValueClass: {
      type   : String,
      default: 'font-weight-regular'
    }
  },
  computed: {
    isBaseCurrencySymbolSingle () {
      return this.baseCurrencySymbol.length === 1
    },

    baseCurrencySymbolSingle () {
      return this.isBaseCurrencySymbolSingle ? this.baseCurrencySymbol : ''
    }
  },
  methods: {
    onItemClick () {
      if (this.expanded) {
        this.$emit('item-chart-legend-click')
      }
    }
  }
}
</script>

<style scoped>
</style>
