import { render, staticRenderFns } from "./BlockToolbar.vue?vue&type=template&id=04568990&scoped=true"
import script from "./BlockToolbar.vue?vue&type=script&lang=js"
export * from "./BlockToolbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04568990",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VToolbar})
