/* eslint-disable no-sequences */

/**
 * Returns true if the provided predicate function returns true for all elements in a collection, false otherwise.
 *
 * Use Array.prototype.every() to test if all elements in the collection return true based on fn.
 * Omit the second argument, fn, to use Boolean as a default.
 *
 * @example all([4, 2, 3], x => x > 1); // true
 * @example all([1, 2, 3]); // true
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
import { isArray } from '../type'
import { pathToValue } from '../object'

/**
 * Returns true if the provided predicate function returns true for at least one element in a collection, false otherwise.
 *
 * Use Array.prototype.some() to test if any elements in the collection return true based on fn. Omit the second argument, fn,
 * to use Boolean as a default.
 *
 * @example any([0, 1, 2, 0], x => x >= 2); // true
 * @example any([0, 0, 1, 0]); // true
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const any = (arr, fn = Boolean) => arr.some(fn)

/**
 * Removes falsy values from an array.
 *
 * Use Array.prototype.filter() to filter out falsy values (false, null, 0, "", undefined, and NaN).
 *
 * @example compact([0, 1, false, 2, '', 3, 'a', 'e' * 23, NaN, 's', 34]); // [ 1, 2, 3, 'a', 's', 34 ]
 *
 * @param arr
 * @returns {*}
 */
export const compact = arr => arr.filter(Boolean)
export { compact as filterFalsy }

/**
 * Create a new array with items based on BaseModel
 *
 * @param Payload
 * @param Model || Resource
 * @returns {*[]}
 */
export const collect = (Payload = [], Model) => {
  const retVal = []
  if (!isArray(Payload)) return []

  if (Model && Payload && isArray(Payload) && Payload.length > 0) {
    Payload.forEach((item, index) => {
      retVal[index] = new Model(item)
    })
  }

  return retVal
}

/**
 * Flattens an array up to the specified depth.
 *
 * Use recursion, decrementing depth by 1 for each level of depth. Use Array.prototype.reduce() and Array.prototype.concat() to merge elements or arrays.
 * Base case, for depth equal to 1 stops recursion. Omit the second argument, depth to flatten only to a depth of 1 (single flatten).
 *
 * @example flatten([1, [2], 3, 4]); // [1, 2, 3, 4]
 * @example flatten([1, [2, [3, [4, 5], 6], 7], 8], 2); // [1, 2, 3, [4, 5], 6, 7, 8]
 *
 * @param arr
 * @param depth
 * @returns {*}
 */
export const flatten = (arr, depth = 1) => arr.reduce((a, v) => a.concat(depth > 1 && Array.isArray(v) ? flatten(v, depth - 1) : v), [])

/**
 * Groups the elements of an array based on the given function.
 *
 * Use Array.prototype.map() to map the values of an array to a function or property name.
 * Use Array.prototype.reduce() to create an object, where the keys are produced from the mapped results.
 *
 * @example groupBy([6.1, 4.2, 6.3], Math.floor); // {4: [4.2], 6: [6.1, 6.3]}
 * @example groupBy(['one', 'two', 'three'], 'length'); // {3: ['one', 'two'], 5: ['three']}
 *
 * @param arr
 * @param fn
 * @returns {*}
 */
export const groupBy = (arr, fn) => arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val, i) => {
  acc[val] = (acc[val] || []).concat(arr[i])
  return acc
}, {})

/**
 * Returns true if at least one element of values is included in arr , false otherwise.
 *
 * Use Array.prototype.some() and Array.prototype.includes() to check if at least one element of values is included in arr.
 *
 * @example includesAny([1, 2, 3, 4], [2, 9]); // true
 * @example includesAny([1, 2, 3, 4], [8, 9]); // false
 *
 * @param arr
 * @param values
 * @returns {*}
 */
export const includesAny = (arr, values) => values.some(v => arr.includes(v))

/**
 * Returns the first element in an array.
 *
 * Use arr[0] to get the first element of the given array and returning it.
 *
 * @example last([1, 2, 3]); // 3
 *
 * @param arr
 * @returns {*}
 */
export const first = arr => (isArray(arr) && arr.length && arr[0]) || null

/**
 * Returns the last element in an array.
 *
 * Use arr.length - 1 to compute the index of the last element of the given array and returning it.
 *
 * @example last([1, 2, 3]); // 3
 *
 * @param arr
 * @returns {*}
 */
export const last = arr => (isArray(arr) && arr.length && arr[arr.length - 1]) || null

/**
 * Removes elements from an array for which the given function returns false.
 *
 * Use Array.prototype.filter() to find array elements that return truthy values and Array.prototype.reduce() to
 * remove elements using Array.prototype.splice(). The func is invoked with three arguments (value, index, array).
 *
 * @example remove([1, 2, 3, 4], n => n % 2 === 0); // [2, 4]
 *
 * @param arr
 * @param func
 * @returns {Array}
 */
export const remove = (arr, func) => Array.isArray(arr) ? arr.filter(func).reduce((acc, val) => {
  arr.splice(arr.indexOf(val), 1)
  return acc.concat(val)
}, []) : []

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 *
 * @example MyData.sort(sortCompareAlphabetically("name"));
 */
export const sortCompareAlphabetically = (property) => {
  let sortOrder = 1

  if (property && property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return property ? pathToValue(property, b).localeCompare(pathToValue(property, a)) : b.localeCompare(a)
    } else {
      return property ? pathToValue(property, a).localeCompare(pathToValue(property, b)) : a.localeCompare(b)
    }
  }
}

/**
 * Returns an array with n elements removed from the beginning.
 *
 * Use Array.prototype.slice() to create a slice of the array with n elements taken from the beginning.
 *
 * @example take([1, 2, 3], 5); // [1, 2, 3]
 * @example take([1, 2, 3], 0); // []
 *
 * @param arr
 * @param n
 * @returns {*}
 */
export const take = (arr, n = 1) => arr.slice(0, n)

/**
 * Returns an array with n elements removed from the end.
 *
 * Use Array.prototype.slice() to create a slice of the array with n elements taken from the end.
 *
 * @example takeRight([1, 2, 3], 2); // [ 2, 3 ]
 * @example takeRight([1, 2, 3]); // [3]
 *
 * @param arr
 * @param n
 * @returns {*}
 */

/**
 * Returns every element that exists in any of the two arrays once.
 *
 * Create a Set with all values of a and b and convert to an array.
 *
 * @example union([1, 2, 3], [4, 3, 2]); // [1,2,3,4]
 *
 * @param a
 * @param b
 * @returns {*[]}
 */
export const union = (a, b) => Array.from(new Set([...a, ...b]))

/**
 * Returns all unique values of an array.
 *
 * Use ES6 Set and the ...rest operator to discard all duplicated values.
 *
 * @example uniqueElements([1, 2, 2, 3, 4, 4, 5]); // [1, 2, 3, 4, 5]
 *
 * @param arr
 * @returns {any[]}
 */
export const uniqueElements = arr => [...new Set(arr)]

/**
 * Filters out the elements of an array, that have one of the specified values.
 *
 * Use Array.prototype.filter() to create an array excluding(using !Array.includes()) all given values.
 *
 * @example without([2, 1, 2, 3], 1, 2); // [3]
 *
 * @param arr
 * @param args
 * @returns {*}
 */
export const without = (arr, ...args) => arr.filter(v => !args.includes(v))

/**
 * Sums the values of the array, or the properties, or the result of the callback.
 *
 * @param  {Array} [payload=null] the array to be summed.
 * @param  {undefined|string|function} [property=null] the property to be summed.
 * @return {*} The sum of values used in the summation.
 * @example <caption>Summing elements</caption>
 * console.log(sum([1, 2, 3])); // 6
 *
 * @example <caption>Summing a property</caption>
 * console.log(collection.sum([
 *     { name: 'Arya Stark', age: 9 },
 *     { name: 'Bran Stark', age: 7 },
 *     { name: 'Jon Snow', age: 14 }
 * ], 'age')); // 30
 *
 * @example <caption>Summing using a callback</caption>
 * console.log(collection.sum([
 *     { name: 'Arya Stark', age: 9 },
 *     { name: 'Bran Stark', age: 7 },
 *     { name: 'Jon Snow', age: 14 }
 * ], i => i.age + 1)); // 33
 */
export const sum = (payload = [], property = null) => {
  if (typeof property === 'string') return payload.reduce((previous, current) => (previous || 0) + (current[property] || 0), 0)
  if (typeof property === 'function') return payload.reduce((previous, current) => (previous || 0) + (property(current) || 0), 0)
  return payload.reduce((previous, current) => (previous || 0) + (current || 0), 0)
}

/**
 * Checks two arrays are equal with Same Length & Each Value Equal
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {false}
 */
export const arrayEquals = (a, b) => isArray(a) && isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])

/**
 * Move item position in array by specified index
 *
 * @param arr
 * @param currentIndex
 * @param newIndex
 * @returns {*}
 */
export const move = (arr, currentIndex, newIndex) => {
  if (newIndex > arr.length - 1) {
    return arr
  }

  arr.splice(newIndex, 0, arr.splice(currentIndex, 1)[0])

  return arr
}
