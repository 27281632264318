import BaseResource                                   from '@/lib/data/resource/BaseResource'
import i18n                                           from '@/lang/lang'
import { toDecimalMark, toNumberFormatNotationShort } from '@/lib/utils/helper'
import { collect }                                    from '@/lib/utils/array'
import AssetCodeNameResource                          from '@/api/resources/asset/AssetCodeNameResource'
import RiskExposuresAssetAggregateResource            from '@/api/resources/risks/RiskExposuresAssetAggregateResource'
import AssetModel                                     from '@/api/models/asset/AssetModel'

export default class RiskExposuresAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Asset = payload.itemName ? (payload.itemName === 'portfolio' ? i18n.t('Common.Asset.Portfolio') : payload.itemName) : ''

    this.DetailAsset = new AssetModel(payload.detailedAsset ?? {})

    this.LocalExposure = payload.localExposure ? toNumberFormatNotationShort(payload.localExposure) : ''
    this.LocalExposureRaw = payload.localExposure ?? ''

    this.Exposure = payload.exposure ? toNumberFormatNotationShort(payload.exposure) : ''
    this.ExposureRaw = payload.exposure ?? ''

    this.ExposurePercent = payload.exposurePercent ? toDecimalMark(payload.exposurePercent) : ''
    this.ExposurePercentRaw = payload.exposurePercent ?? ''

    this.Volatility = payload.volatility ? toDecimalMark(payload.volatility) + '%' : ''
    this.VolatilityRaw = payload.volatility ?? ''

    this.AggregateItem = payload?.aggregateItem || false
    this.PerCashflowCodes = payload?.perCashflowCodes || []
    this.PerCashflowItems = collect(payload?.perCashflowItems || [], RiskExposuresAssetAggregateResource)

    this.PerCashflowNames = payload.perCashflowNames ?? []
    this.PerCashflowCodeNames = collect(payload?.perCashflowCodeNames || [], AssetCodeNameResource)

    this.DisplayLocalExposure = payload.displayLocalExposure ?? false
    this.DisplayUnit = payload.displayUnit ?? ''
    this.DisplayUnitPosition = payload.displayUnitPosition ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
