/* eslint-disable no-sequences */

/**
 * Creates a deep clone of an object.
 *
 * Use recursion. Check if the passed object is null and, if so, return null.
 * Use Object.assign() and an empty object ({}) to create a shallow clone of the original.
 * Use Object.keys() and Array.prototype.forEach() to determine which key-value pairs need to be deep cloned.
 *
 * @example
 * const a = { foo: 'bar', obj: { a: 1, b: 2 } };
 * const b = deepClone(a); // a !== b, a.obj !== b.obj
 *
 * @param obj
 * @return {null|*}
 */
import { sortCompareAlphabetically } from '../array'

export const deepClone = obj => {
  if (obj === null) return null
  const clone = Object.assign({}, obj)
  Object.keys(clone).forEach(
    key => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  )
  return Array.isArray(obj) && obj.length
    ? (clone.length = obj.length) && Array.from(clone)
    : Array.isArray(obj)
      ? Array.from(obj)
      : clone
}

export const mergeDeep = (target, ...args) => {
  // deep merge the object into the target object
  const merger = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // if the property is a nested object
          target[prop] = mergeDeep(target[prop], obj[prop])
        } else {
          // for regular property
          target[prop] = obj[prop]
        }
      }
    }
  }

  // iterate through all objects and
  // deep merge them with target
  for (let i = 0; i < args.length; i++) {
    merger(args[i])
  }

  return target
}

/**
 * Deep freezes an object.
 *
 * Calls Object.freeze(obj) recursively on all unfrozen properties of passed object that are instanceof object.
 *
 * @example
 * 'use strict';
 *
 * const o = deepFreeze([1, [2, 3]]);
 *
 * o[0] = 3; // not allowed
 * o[1][0] = 4; // not allowed as well
 *
 *
 * @param obj
 * @return {void | ReadonlyArray<unknown>}
 */
export const deepFreeze = obj => Object.keys(obj).forEach(prop => !(obj[prop] instanceof Object) || Object.isFrozen(obj[prop]) ? null : deepFreeze(obj[prop])) || Object.freeze(obj)

/**
 * Deep maps an object's keys.
 *
 * Creates an object with the same values as the provided object and keys generated by running the provided function for each key.
 * Use Object.keys(obj) to iterate over the object's keys.
 * Use Array.prototype.reduce() to create a new object with the same values and mapped keys using fn.
 *
 * @example
 * const obj = {
 *   foo: '1',
 *   nested: {
 *     child: {
 *       withArray: [
 *         {
 *           grandChild: ['hello']
 *         }
 *       ]
 *     }
 *   }
 * };
 *  const upperKeysObj = deepMapKeys(obj, key => key.toUpperCase());
 *
 *  {
 *   "FOO":"1",
 *   "NESTED":{
 *     "CHILD":{
 *       "WITHARRAY":[
 *         {
 *           "GRANDCHILD":[ 'hello' ]
 *         }
 *       ]
 *     }
 *   }
 * }
 *
 * @param obj
 * @param f
 * @return {{}}
 */
export const deepMapKeys = (obj, f) =>
  Array.isArray(obj)
    ? obj.map(val => deepMapKeys(val, f))
    : typeof obj === 'object'
      ? Object.keys(obj).reduce((acc, current) => {
        const val = obj[current]
        acc[f(current)] =
          val !== null && typeof val === 'object' ? deepMapKeys(val, f) : (acc[f(current)] = val)
        return acc
      }, {})
      : obj

/**
 * Returns the target value in a nested JSON object, based on the given key.
 *
 * Use the in operator to check if target exists in obj. If found, return the value of obj[target],
 * otherwise use Object.values(obj) and Array.prototype.reduce() to recursively call dig on each
 * nested object until the first matching key/value pair is found.
 *
 * @example
 * const data = {
 *   level1: {
 *     level2: {
 *       level3: 'some data'
 *     }
 *   }
 * };
 * dig(data, 'level3'); // 'some data'
 * dig(data, 'level4'); // undefined
 *
 * @param obj
 * @param target
 * @return {any}
 */
export const dig = (obj, target) =>
  target in obj
    ? obj[target]
    : Object.values(obj).reduce((acc, val) => {
      if (acc !== undefined) return acc
      if (typeof val === 'object') return dig(val, target)
    }, undefined)

/**
 * Given a flat array of objects linked to one another, it will nest them recursively. Useful for nesting comments, such as the ones on reddit.com.
 *
 * Use recursion. Use Array.prototype.filter() to filter the items where the id matches the link, then Array.prototype.map() to map each one to
 * a new object that has a children property which recursively nests the items based on which ones are children of the current item. Omit the second
 * argument, id, to default to null which indicates the object is not linked to another one (i.e. it is a top level object). Omit the third argument,
 * link, to use 'parent_id' as the default property which links the object to another one by its id.
 *
 * @example
 * // One top level comment
 * const comments = [
 *  { id: 1, parent_id: null },
 *  { id: 2, parent_id: 1 },
 *  { id: 3, parent_id: 1 },
 *  { id: 4, parent_id: 2 },
 *  { id: 5, parent_id: 4 }
 * ];
 * const nestedComments = nest(comments); // [{ id: 1, parent_id: null, children: [...] }]
 *
 *
 * @param items
 * @param id
 * @param link
 * @return {*}
 */
export const nest = (items, id = null, link = 'parent_id') => items.filter(item => item[link] === id).map(item => ({
  ...item,
  children: nest(items, item.id)
}))

/**
 * Sorts an object by key name
 *
 * @param o
 * @return {{}|*}
 */
export function sortObjectDeepByKey (o) {
  if (typeof o !== 'object' || !o) {
    return o
  }
  // eslint-disable-next-line no-return-assign, no-sequences
  return Object.keys(o).sort(sortCompareAlphabetically('')).reduce((c, key) => (c[key] = sortObjectDeepByKey(o[key]), c), {})
}

/**
 * Get size of arrays, objects or strings.
 *
 * Get type of val (array, object or string). Use length property for arrays.
 * Use length or size value if available or number of keys for objects.
 * Use size of a Blob object created from val for strings.
 * Split strings into array of characters with split('') and return its length.
 *
 * @example size([1, 2, 3, 4, 5]); // 5
 * @example size('size'); // 4
 * @example size({ one: 1, two: 2, three: 3 }); // 3
 *
 * @param val
 * @return {*}
 */
export const size = val =>
  Array.isArray(val)
    ? val.length
    : val && typeof val === 'object'
      ? val.size || val.length || Object.keys(val).length
      : typeof val === 'string'
        ? new Blob([val]).size
        : 0

/**
 * Applies a function against an accumulator and each key in the object (from left to right).
 *
 * Use Object.keys(obj) to iterate over each key in the object, Array.prototype.reduce() to call the apply the specified function against the given accumulator.
 *
 * @example
 * transform(
 *  { a: 1, b: 2, c: 1 },
 *  (r, v, k) => {
 *     (r[v] || (r[v] = [])).push(k);
 *     return r;
 *   },
 *  {}
 *  ); // { '1': ['a', 'c'], '2': ['b'] }
 *
 *
 * @param obj
 * @param fn
 * @param acc
 * @return {string}
 */
export const transform = (obj, fn, acc) => Object.keys(obj).reduce((a, k) => fn(a, obj[k], k, obj), acc)

/**
 * Returns the value of a nested object property from a dot (.) notation string
 *
 * @param path
 * @param data
 * @return {*}
 */
export const pathToValue = (path, data) => {
  let value = null
  if (typeof path === 'string') {
    if (!path.includes('.')) return data[path]
    path = path.split('.')
  }

  try {
    value = path.reduce((pValue, cValue) => {
      return pValue[cValue]
    }, data) || null
  } catch (e) {
    value = null
  }
  return value
}

/**
 * Iterate over Object or Array with forEach
 *
 * @param obj {Object || Array}
 * @param cb Callback function
 * @returns {Object || Array}
 */
export const forEach = (obj, cb) => {
  let i, len
  if (Array.isArray(obj)) {
    for (i = 0, len = obj.length; i < len; i++) if (cb(obj[i], i, obj) === false) break
  } else {
    for (i in obj) if (cb(obj[i], i, obj) === false) break
  }
  return obj
}
