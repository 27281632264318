<template>
  <view-data-container
    :button-title="$t('Risks.Button.New.Title')"
    :loading="isLoadingData"
    :no-data-title="$t('Risks.Message.NoArchiveData.Title')"
  >
    <v-row v-if="items && items.length">
      <template
        v-for="(item, i) in items"
      >
        <v-col
          :key="`tab-archive-items-${i}`"
          cols="12"
          lg="4"
          md="6"
          style="padding: 10px"
        >
          <v-lazy
            :key="`lazy-risk-archive-tab-items-${i}`"
            :options="{threshold: .5}"
            transition="fade-transition"
          >
            <risk-archive-item-card
              :active-analysis="item.Active"
              :base-currency-symbol="item.BaseCurrencyDisplayName"
              :card-event="true"
              :date-created="item.CreatedAtFmt"
              :pie-values="item.PieValues"
              :tags="item.TagsTrimmed"
              :title="item.Name"
              :title-prefix="$t('Dashboard.Block.LiveRisk.Label.Risk')"
              :title-prefix-tooltip="$t('Dashboard.Block.LiveRisk.InfoTip.Risk')"
              :title-value="item.TotalRisk || ''"
              @card-click="onItemCardClick(item)"
              @archive-item-active-button-click="onItemCardActiveClick(item)"
              @archive-item-delete-button-click="onItemCardDeleteClick(item)"
              @archive-item-new-button-click="onItemCardNewClick(item)"
            />
          </v-lazy>
        </v-col>
      </template>
    </v-row>
  </view-data-container>
</template>

<script>

import RiskArchiveItemCard                 from '@/theme/default/components/common/RiskArchiveItemCard'
import API                                 from '@/api/Api'
import { clone }                           from '@/lib/utils/helper'
import { collect }                         from '@/lib/utils/array'
import RiskArchiveAnalysisResource         from '@/api/resources/risks/RiskArchiveAnalysisResource'
import RiskSummaryResource                 from '@/api/resources/risks/RiskSummaryResource'
import AssetModel                          from '@/api/models/asset/AssetModel'
import ViewDataContainer                   from '@/theme/default/components/common/ViewDataContainer'
import viewHasData                         from '@/mixins/viewHasData'
import RiskAnalysisNewFromExistingResource from '@/api/resources/risks/RiskAnalysisNewFromExistingResource'
import RiskAnalysisModel                   from '@/api/models/risks/RiskAnalysisModel'

export default {
  name      : 'RisksAnalysisArchive',
  components: {
    ViewDataContainer,
    RiskArchiveItemCard
  },
  directives: {},
  mixins    : [viewHasData],
  props     : {},
  dataStore : {
    items               : 'Risks.Archive.Data',
    riskActiveData      : 'Risks.Active.Data',
    riskSummaryData     : 'Risks.Summary.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      breadcrumbItems: [{
        text    : this.$t('Risks.Title'),
        disabled: false
      }]
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      this.isLoadingData = true

      API.Resource.Risks.Archive(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },

    handleResponse (response) {
      const result = API.responseData(response)
      const data = result?.listAnalyses || result?.listAllAnalyses || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)

        // Store data to DataStore
        this.items = collect(o, RiskArchiveAnalysisResource)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardClick (item) {
      this.getItemData(item)

      this.$router.push({
        name  : 'RisksAnalysisShow',
        params: {
          id              : item.Id,
          riskAnalysisId  : item.Id,
          riskAnalysisName: item.Name
        }
      })
    },

    getItemData (item) {
      API.Resource.Risks.Summary(item.Id)
        .then(response => {
          this.handleItemResponse(response, item)
        })
        .catch(e => {
          this.handleItemResponse(e.response, item)
        })
        .finally(() => {})
    },

    handleItemResponse (response, item) {
      const data = API.responseData(response)?.findAnalysis || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const b = new AssetModel(o.baseCurrency ?? null)
        const v = new RiskSummaryResource(o)

        // Store data to DataStore
        this.$set(this.riskSummaryData, item.Id, v)
        this.$set(this.riskBaseCurrencyData, item.Id, b)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * Set Analysis as active
     *
     * @param item
     */
    onItemCardActiveClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Risks.Tab.Archive.Confirm.Active.Title'),
        this.$t('Risks.Tab.Archive.Confirm.Active.Body', { Name: item.Name }),
        {
          color : 'synthesis-ui-green-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Risks.Tab.Archive.Confirm.Active.ButtonYes') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Risks.SetActiveAnalysis(item.Id)
            .then(response => {
              this.handleSetActiveAnalysisResponse(response)
            })
            .catch(e => {
              this.handleSetActiveAnalysisResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },

    handleSetActiveAnalysisResponse (response) {
      const data = API.responseData(response)?.activeAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.riskActiveData = null
        this.activeTab = 0

        this.$nextTick(() => {
          this.getData()
        })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * Create an Analysis from existing one
     *
     * @param item
     */
    onItemCardNewClick (item) {
      API.Resource.Risks.NewRiskAnalysisFromExisting(item.Id)
        .then(response => {
          this.handleNewRiskAnalysisFromExistingResponse(response)
        })
        .catch(e => {
          this.handleNewRiskAnalysisFromExistingResponse(e.response)
        })
        .finally(() => {})
    },

    handleNewRiskAnalysisFromExistingResponse (response) {
      const data = API.responseData(response)?.findAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const initData = new RiskAnalysisModel(new RiskAnalysisNewFromExistingResource(data))

        this.$router.push({
          name  : 'RisksAnalysisNew',
          params: { item: initData }
        })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * Delete non-active Analysis
     *
     * @param item
     */
    onItemCardDeleteClick (item) {
      if (!item || !item.Id) return

      this.$root.$confirmDialog(
        this.$t('Risks.Tab.Archive.Confirm.Delete.Title'),
        this.$t('Risks.Tab.Archive.Confirm.Delete.Body', { Name: item.Name }),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        if (response) {
          API.Resource.Risks.DeleteAnalysis(item.Id)
            .then(response => {
              this.handleDeleteAnalysisResponse(response)
            })
            .catch(e => {
              this.handleDeleteAnalysisResponse(e.response)
            })
            .finally(() => {})
        }
      })
    },

    handleDeleteAnalysisResponse (response) {
      const riskAnalysisId = API.responseData(response)?.deleteAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && riskAnalysisId) {
        this.deleteStorageDataById(riskAnalysisId)

        this.$nextTick(() => {
          this.getData()
        })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    deleteStorageDataById (riskAnalysisId) {
      if (this.riskActiveData && (this.riskActiveData?.Id === riskAnalysisId)) {
        this.riskActiveData = null
      }
      this.$delete(this.riskSummaryData, riskAnalysisId)
      this.$delete(this.riskBaseCurrencyData, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.Correlations.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.Seasonality.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.Forecasts.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.Exposures.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.ValueAtRisk.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.HistoricalSpectrum.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.ForwardSimulations.Data, riskAnalysisId)
      this.$delete(this.$dataStore.Risks.ExtremeEvents.Data, riskAnalysisId)
    }
  }
}
</script>

<style scoped>

</style>
