/**
 * Converts a number in bytes to a human-readable string.
 *
 * Use an array dictionary of units to be accessed based on the exponent. Use Number.toPrecision() to truncate the number to a certain number of digits.
 * Return the prettified string by building it up, taking into account the supplied options and whether it is negative or not. Omit the second argument,
 * precision, to use a default precision of 3 digits. Omit the third argument, addSpace, to add space between the number and unit by default.
 *
 * @example prettyBytes(1000); // "1 KB"
 * @example prettyBytes(-27145424323.5821, 5); // "-27.145 GB"
 * @example prettyBytes(123456789, 3, false); // "123MB"
 *
 * @param num
 * @param precision
 * @param addSpace
 * @returns {string}
 */
import i18n from '@/lang/lang'

/**
 * Use toLocaleString() to convert a float-point arithmetic to the Decimal mark form. It makes a comma separated string from a number.
 *
 * @example toDecimalMark(12305030388.9087); // "12,305,030,388.909"
 *
 * @param num
 * @param locale
 * @param decimals
 * @returns {string}
 */
export const toDecimalMark = (num, decimals = 2, locale = i18n.code) => {
  return num ? num.toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }) : ''
}

export const toDecimalSignificant = (num, decimals = 3, locale = i18n.code) => {
  return num ? num.toLocaleString(locale, {
    maximumSignificantDigits: decimals
  }) : ''
}

/**
 * Use toLocaleString() to convert a float-point arithmetic to the Decimal mark form. It makes a comma separated string from a number.
 *
 * @example toDecimalMarkRange(12305030388.9087); // "12,305,030,388.909"
 *
 * @param num
 * @param locale
 * @param minDecimals
 * @param maxDecimals
 * @returns {string}
 */
export const toDecimalMarkRange = (num, minDecimals = 0, maxDecimals = 2, locale = i18n.code) => {
  return num ? num.toLocaleString(locale, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  }) : ''
}

export const toNumberFormatNotationShort = (num, lower = true, locale = i18n.code) => {
  if (!num) return ''

  const o = num.toLocaleString(locale, {
    notation                : 'compact',
    compactDisplay          : 'short',
    maximumSignificantDigits: 3
  })

  return lower ? o.toLocaleLowerCase(locale) : o
}

/**
 * Returns cloned object
 *
 * @example clone([1, 2, 3]); // [1, 2, 3]
 *
 * @param obj
 * @returns {any}
 */
export const clone = obj => JSON.parse(JSON.stringify(obj))
